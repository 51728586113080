import { useState } from "react";
import './MediaArchive.scss';
import useBasicTourTrans from "../util/useBasicTourTrans";
const loc = '/media-archive';
function MediaArchive() {
  const [show, setShow] = useState(false);
  const { onPageTransInDone, onPageTransOutDone } = useBasicTourTrans({
    setShow,
    name: loc,
    isLocation: loc,
    autoCompleteLoad: true
  });

  const onTransition = (e: React.TransitionEvent<HTMLDivElement>) => {
    if (e.currentTarget) {
      const opacity = window.getComputedStyle(e.currentTarget).opacity;
      if (e.propertyName === 'opacity' && opacity === '1') {
        onPageTransInDone();
      } else if (e.propertyName === 'opacity' && opacity === '0') {
        onPageTransOutDone();
      }
    }
  };
  return <div className={["media-archive", show ? "show" : ""].join(' ')} onTransitionEnd={onTransition}>
    <h2>Media Archive Coming Soon</h2>
    <p>A archive of historic and current imagery from Sutro Tower Coming soon.</p>
  </div>;
}
export default MediaArchive;