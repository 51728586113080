import { createContext } from "react";
interface LayoutContext {
  showHeader: boolean,
  setShowHeader: React.Dispatch<React.SetStateAction<boolean>>,
  gradientOn: boolean,
  setGradient: React.Dispatch<React.SetStateAction<boolean>>,
  addGradient: (owner: string) => void,
  removeGradient: (owner: string) => void,
  isCloseOverrideActive: boolean,
  setCloseOverrideActive: React.Dispatch<React.SetStateAction<boolean>>,
  isTourSubNavOn: boolean,
  setTourSubNavOn: React.Dispatch<React.SetStateAction<boolean>>,
  requestLoading: (key: string) => void,
  releaseLoading: (key: string) => void

}

const LayoutContext = createContext<LayoutContext>({
  showHeader: true,
  setShowHeader() { },
  gradientOn: false,
  setGradient() { },
  addGradient() { },
  removeGradient() { },
  isCloseOverrideActive: false,
  setCloseOverrideActive() { },
  isTourSubNavOn: false,
  setTourSubNavOn() { },
  requestLoading(key: string) { false && key; },
  releaseLoading(key: string) { false && key; }
});
export default LayoutContext;