import { useContext } from "react";
import NavigationContext, { NavigationContextType } from "../contexts/NavigationContext";
import { useSelector } from "@xstate/react";

function useLocationContext() {
  const navContext = useContext(NavigationContext);
  return useLocationContextWithContext(navContext);
}

export function useLocationContextWithContext(navContext: NavigationContextType) {

  return useSelector(navContext.navManager.navigationActor, (snap) => {

    const { context } = snap;
    const isInitialLoad = snap.matches('initLoading');
    const hasTransitionedOut = isInitialLoad || snap.matches({ transition: { transitioningOut: 'complete' } });
    const hasTransitionedIn = (isInitialLoad && snap.matches({ initLoading: { transitioningIn: 'complete' } })) ||
      snap.matches({ transition: { transitioningIn: 'complete' } });
    const hasLoaded = (isInitialLoad && snap.matches({ initLoading: { loading: 'complete' } })) ||
      snap.matches({ transition: { loading: 'complete' } }) || snap.matches("viewing");
    const { currentLocation, pendingLocation, queuedLocation } = context;
    // console.log(snap.value);
    // console.log({ currentLocation, pendingLocation, queuedLocation, isInitialLoad, hasTransitionedOut, hasTransitionedIn });
    return {
      currentLocation,
      pendingLocation,
      queuedLocation,
      isInitialLoad,
      hasLoaded,
      hasTransitionedOut,
      hasTransitionedIn,
      navContext
    };
  });
}

export default useLocationContext;