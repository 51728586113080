
import LayoutContext from "./contexts/LayoutContext";

import { useState, lazy, Suspense, useEffect } from "react";


import SutroLink from "./components/SutroLink.tsx";
import useLocationContext from "./util/useLocationContext.tsx";
import TOUR_DATA, { TOUR_CHAPTERS, TOUR_CHAPTERS_ORDER, TOUR_CHAPTERS_TO_URLS, TourChapter } from "./components/TourData.ts";
import LoadingSpinner from "./components/LoadingSpinner.tsx";
import About from "./pages/About.tsx";
import MediaArchive from "./pages/MediaArchive.tsx";

const Tour = lazy(() => import('./pages/Tour.tsx'));
const Home = lazy(() => import('./pages/Home.tsx'));
const Documentary = lazy(() => import('./pages/Documentary.tsx'));
const TowerWalk = lazy(() => import('./pages/TowerWalk.tsx'));


function Layout() {
  const [showHeader, setShowHeader] = useState(true);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isCloseOverrideActive, setCloseOverrideActive] = useState(false);
  const [isTourSubNavOn, setTourSubNavOn] = useState(false);
  const [gradientOn, setGradient] = useState(false);
  const [gradientSet, setGradientSet] = useState<Set<string>>(new Set<string>());
  const [loadingSet, setLoadingSet] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const requestLoading = (key: string) => {
    const t = new Set(loadingSet);
    t.add(key);
    console.log(`loading: ${Array.from(t).join(',')}`);
    setLoadingSet(Array.from(t));
    setIsLoading(Array.from(t).length > 0);
  };
  const releaseLoading = (key: string) => {
    const t = new Set(loadingSet);
    t.delete(key);
    console.log(`loading: ${Array.from(t).join(',')}`);
    setLoadingSet(Array.from(t));
    setIsLoading(Array.from(t).length > 0);
  };
  const { pendingLocation, currentLocation, isInitialLoad, hasLoaded } = useLocationContext();
  // const isLoading = loadingSet.length > 1 || (pendingLocation && !hasLoaded);

  // home is only active if not initial load and one of the location is root
  // or if it is the initialLoad and both pending and current are root
  const homeActive =
    !isInitialLoad && [pendingLocation, currentLocation].some(v => (v !== undefined && (v === '/' || v === ''))) ||
    [pendingLocation, currentLocation].every(v => (v !== undefined && (v === '/' || v === '')));
  const tourActive = [pendingLocation, currentLocation].some(v => v && v.indexOf('/tour') > -1);
  const documentaryActive = [pendingLocation, currentLocation].indexOf('/documentary') > -1;
  const aboutActive = [pendingLocation, currentLocation].indexOf('/about') > -1;
  const towerWalkActive = [
    pendingLocation && pendingLocation.indexOf('/the-view') > -1,
    currentLocation.indexOf('/the-view') > -1]
    .some(e => e);
  const mediaArchiveActive = [pendingLocation, currentLocation].some(v => v && v.indexOf('/media-archive') === 0);

  // close the details overlay on page transition
  useEffect(() => {
    setCloseOverrideActive(false);
  }, [pendingLocation]);

  const closeNav = () => {
    if (isNavOpen) {
      setIsNavOpen(false);
    }
  };

  const addGradient = (owner: string) => {
    gradientSet.add(owner);
    setGradient(true);
  };
  const removeGradient = (owner: string) => {
    gradientSet.delete(owner);
    setGradient(!!gradientSet.size);
  };

  return <div className={[gradientOn ? 'gradient' : '', "main"].join(' ')}>
    <LayoutContext.Provider value={{
      showHeader, setShowHeader,
      gradientOn, setGradient, addGradient, removeGradient,
      isCloseOverrideActive: isCloseOverrideActive, setCloseOverrideActive: setCloseOverrideActive,
      isTourSubNavOn, setTourSubNavOn,
      releaseLoading, requestLoading
    }}>
      <header className={['main', showHeader ? 'stick' : ''].join(' ')}>
        <h1><SutroLink to="/" onClick={closeNav}><img src="/imgs/site-logo.svg" alt="Explore Sutro Tower" /></SutroLink></h1>
        <nav className={[isNavOpen ? 'open' : '', isCloseOverrideActive ? 'close-override' : ''].join('')} onClick={closeNav}>
          <div className="menu-header">
            <div className="nav-title">{(
              currentLocation.indexOf('/tour') === 0 &&
              TOUR_DATA[currentLocation] &&
              !currentLocation.match(/\/intro$/) &&
              !currentLocation.match(/\/welcome$/) &&
              !isCloseOverrideActive
            ) ? `TOUR: ${TOUR_DATA[currentLocation].chapter}` : null}</div>
            <button className="menu-toggle" aria-disabled="true" onClick={() => {
              if (isCloseOverrideActive) {
                setCloseOverrideActive(false);
                return;
              }
              setIsNavOpen(!isNavOpen);
            }}>
              <span className="off-label"><span>Menu</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
                  <path fillRule="evenodd" clipRule="evenodd" d="M1.22648 2L0.0717773 0H13.9282L12.7735 2H1.22648ZM2.38118 4L3.53588 6H10.4641L11.6188 4H2.38118ZM5.84528 10L4.69058 8H9.30938L8.15468 10H5.84528Z" fill="#D9D9D9" />
                </svg>
              </span>
              <span className="on-label"><svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                <path d="M1 1L26 26" stroke="#D9D9D9" strokeWidth="2" strokeLinecap="round" />
                <path d="M26 1L0.999999 26" stroke="#D9D9D9" strokeWidth="2" strokeLinecap="round" />
              </svg></span>
            </button>
          </div>
          <div className="menu-body">
            <ul>
              <li className={currentLocation.indexOf('/tour') === 0 ? 'active' : ''}>
                <SutroLink to="/tour" onClick={closeNav}>Tour The Tower</SutroLink>
                <ul>{TOUR_CHAPTERS_ORDER.filter(v => v != TourChapter.WrapUp).map(chap => {
                  return <li key={chap}><SutroLink to={TOUR_CHAPTERS[chap][0].url}
                    onClick={closeNav}
                    isActive={(url: string) => { return url.indexOf(TOUR_CHAPTERS_TO_URLS[chap]) === 0; }}
                  >{chap}</SutroLink></li>;
                })}</ul>
              </li>
              <li className={currentLocation.indexOf('/the-view') === 0 ? 'active' : ''}>
                <SutroLink to="/the-view" onClick={closeNav} isActive={(url: string) => url.indexOf('/the-view') === 0}>See The View</SutroLink>
                <ul>
                  <li>
                    <SutroLink to="/the-view/zoom/west" onClick={closeNav} isActive={(url: string) => url.indexOf('/the-view/zoom/west') === 0}>West Leg</SutroLink>
                  </li>
                  <li>
                    <SutroLink to="/the-view/zoom/north" onClick={closeNav} isActive={(url: string) => url.indexOf('/the-view/zoom/north') === 0}>North Leg</SutroLink>
                  </li>
                  <li>
                    <SutroLink to="/the-view/zoom/south" onClick={closeNav} isActive={(url: string) => url.indexOf('/the-view/zoom/south') === 0}>South Leg</SutroLink>
                  </li>
                </ul>
              </li>
              <li>
                <SutroLink to="/documentary" onClick={closeNav}>Documentary</SutroLink>
              </li>
              <li>
                <SutroLink to="/media-archive" onClick={closeNav}>Archive</SutroLink>
              </li>
              <li>
                <SutroLink to="/about" onClick={closeNav}>About</SutroLink>
              </li>
              {/* <li><a href="https://imagearchive.sutrotower.com">Archive</a></li> */}
            </ul>
          </div>
        </nav>
      </header>
      <Suspense fallback={<LoadingSpinner />}>
        {homeActive ? <Home /> : null}
      </Suspense>
      <Suspense fallback={<LoadingSpinner />}>
        {tourActive ? <Tour /> : null}
      </Suspense>
      <Suspense fallback={<LoadingSpinner />}>
        {documentaryActive ? <Documentary /> : null}
      </Suspense>
      <Suspense fallback={<LoadingSpinner />}>
        {towerWalkActive ? <TowerWalk /> : null}
      </Suspense>
      <Suspense fallback={<LoadingSpinner />}>
        {aboutActive ? <About /> : null}
      </Suspense>
      <Suspense fallback={<LoadingSpinner />}>
        {mediaArchiveActive ? <MediaArchive /> : null}
      </Suspense>

      {isLoading ? <LoadingSpinner /> : null}

      {/* <NotFound isActive={noneActive} /> */}
      <div id="mobile-layout-spy"></div>
      <div id="min-height">
        <p>Explore Sutro Tower requires a larger canvas.<br />Please expand your window or rotate your device</p>
      </div>
    </LayoutContext.Provider>
  </div>;
}
export default Layout;