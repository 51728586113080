import { lerp, FROM_RAD, TO_RAD } from "../util/math";
import sitePages from '../site-structure.json';
import isIos from "../util/isIos";
interface ITourPanoImageConstraintsOptions {
  isHFov?: boolean,
  hFov?: number,
  vFov?: number;
  minHFov?: number;
  maxHFov?: number;
  minVFov?: number;
  maxVFov?: number;
  mobileHOffset?: number;
  mobileVOffset?: number;
}
interface KRPanoConstraints {
  fov: number,
  hlookatmax: number,
  hlookatmin: number,
  vlookatmax: number,
  vlookatmin: number,
}
interface GetKRPanoConstraints {
  toKRPanoArgs(wToHAspect: number, mobile: boolean, t: number): KRPanoConstraints
}

export type KRPanoViewSettings = {
  fov: number,
  hlookatmax: number,
  hlookatmin: number,
  hlookat?: number,
  vlookatmax: number,
  vlookatmin: number,
  vlookat?: number,
  limitview: boolean,
  fovtype: 'HFOV' | 'VFOV',
};

export class TourPanoImageConstraints implements ITourPanoImageConstraintsOptions, GetKRPanoConstraints {
  isHFov: boolean = true;
  hFov: number = 90;
  vFov: number = 90;
  minHFov: number = -20;
  maxHFov: number = 20;
  minVFov: number = -20;
  maxVFov: number = 20;
  mobileHOffset: number = 0;
  mobileVOffset: number = 0;
  name: string | undefined;
  constructor(defaults: ITourPanoImageConstraintsOptions = {}) {
    this.isHFov = defaults.isHFov ?? this.isHFov;
    this.hFov = defaults.hFov ?? this.hFov;
    this.vFov = defaults.vFov ?? this.vFov;
    this.minHFov = defaults.minHFov ?? this.minHFov;
    this.maxHFov = defaults.maxHFov ?? this.maxHFov;
    this.minVFov = defaults.minVFov ?? this.minVFov;
    this.maxVFov = defaults.maxVFov ?? this.maxVFov;
    this.mobileHOffset = defaults.mobileHOffset ?? this.mobileHOffset;
    this.mobileVOffset = defaults.mobileVOffset ?? this.mobileVOffset;
  }
  toKRPanoArgs(wToHAspect: number, mobile: boolean): KRPanoViewSettings {


    const fov = this.hFovForAspect(wToHAspect);


    const hlookatmin = this.minHFov + (mobile ? this.mobileHOffset : 0);
    const hlookatmax = this.maxHFov + (mobile ? this.mobileHOffset : 0);
    const vlookatmin = this.minVFov + (mobile ? this.mobileVOffset : 0);
    const vlookatmax = this.maxVFov + (mobile ? this.mobileVOffset : 0);
    // console.log(fov);
    const out: KRPanoViewSettings = {
      fov,
      hlookatmax,
      hlookatmin,
      vlookatmax,
      vlookatmin,
      limitview: true,
      fovtype: 'HFOV',
    };
    // console.log(JSON.stringify(out));
    return out;
  }
  hFovForAspect(wToHAspect: number): number {
    let fov = this.hFov;
    const vFovForAspectAndHFov = Math.atan(Math.tan(this.hFov * TO_RAD * 0.5) * (1 / wToHAspect)) * 2 * FROM_RAD;
    // targeting VFOV
    if (!this.isHFov || vFovForAspectAndHFov > this.vFov) {
      const hFovForAspectAndVFov = Math.atan(Math.tan(this.vFov * TO_RAD * 0.5) * wToHAspect) * 2 * FROM_RAD;
      if (hFovForAspectAndVFov > this.hFov) {
        fov = this.hFov;
      } else {
        fov = hFovForAspectAndVFov;
      }
    }
    return fov;
  }
  toJSON(): string {
    return JSON.stringify({
      isHFov: this.isHFov,
      hFov: this.hFov,
      vFov: this.vFov,
      mobileHOffset: this.mobileHOffset,
      mobileVOffset: this.mobileVOffset,
      maxHFov: this.maxHFov,
      minHFov: this.minHFov,
      maxVFov: this.maxVFov,
      minVFov: this.minVFov,
    });
  }
  static lerp(a: TourPanoImageConstraints, b: TourPanoImageConstraints, wToHAspect: number, mobile: boolean, t: number, forceCenter: boolean = false): KRPanoViewSettings {

    const AHFov = a.hFovForAspect(wToHAspect);
    const BHFov = b.hFovForAspect(wToHAspect);
    const out: KRPanoViewSettings = {

      fov: lerp(AHFov, BHFov, t),
      hlookatmin: (forceCenter ? 0 : lerp(a.minHFov, b.minHFov, t)) +
        (mobile ? lerp(a.mobileHOffset, b.mobileHOffset, t) : 0),
      hlookatmax: (forceCenter ? 0 : lerp(a.maxHFov, b.maxHFov, t)) +
        (mobile ? lerp(a.mobileHOffset, b.mobileHOffset, t) : 0),
      vlookatmin: (forceCenter ? 0 : lerp(a.minVFov, b.minVFov, t)) +
        (mobile ? lerp(a.mobileVOffset, b.mobileVOffset, t) : 0),
      vlookatmax: (forceCenter ? 0 : lerp(a.maxVFov, b.maxVFov, t)) +
        (mobile ? lerp(a.mobileVOffset, b.mobileVOffset, t) : 0),
      limitview: true,
      fovtype: 'HFOV',
    };

    return out;
  }
}
interface MediaSource {
  src: string
}
interface TourPanoImageData extends MediaSource {
  srcHFov: number;
  srcVFov: number;
  src: string;
}
export class TourImage extends TourPanoImageConstraints implements TourPanoImageData {
  src: string;
  srcHFov: number = 130;
  srcVFov: number = 130;
  isImage: boolean = true;
  constructor({
    src,
    constraints,
    srcHFov,
    srcVFov
  }: {
    src: string,
    constraints?: ITourPanoImageConstraintsOptions,
    srcHFov?: number,
    srcVFov?: number,
  }) {
    super(constraints);
    this.src = src;
    if (srcHFov !== undefined) {
      this.srcHFov = srcHFov;
    }
    if (srcVFov !== undefined) {
      this.srcVFov = srcVFov;
    }
  }
}

export class TourPanoVideo implements TourPanoImageData, MediaSource {
  start: TourPanoImageConstraints;
  end: TourPanoImageConstraints;
  src: string;
  srcHFov: number = 130;
  srcVFov: number = 130;
  constructor({ start,
    end,
    src,
    srcHFov,
    srcVFov }: {
      start: TourPanoImageConstraints,
      end: TourPanoImageConstraints,
      src: string,
      srcHFov?: number,
      srcVFov?: number,
    }) {
    this.src = src;
    this.start = start;
    this.end = end;
    this.srcHFov = srcHFov ?? this.srcHFov;
    this.srcVFov = srcVFov ?? this.srcVFov;

  }
  toKRPanoArgs(wToHAspect: number, mobile: boolean, t: number): KRPanoViewSettings {
    return TourPanoImageConstraints.lerp(this.start, this.end, wToHAspect, mobile, t, true);
  }
}

// keep these two in sync
export const WindowSizeToLevel = [[2500, 4], [1800, 4], [1000, 3], [650, 2], [0, 1]];
export const LevelToResolution = [
  1024,
  1536,
  2048,
  3072,
  4096];

let maxTextureSize = -1;
function getMaxTexSize() {
  while (maxTextureSize == -1) {
    const element = document.getElementById('gl-spy');
    if (!element) { maxTextureSize = 1024; break; }
    const canvas: HTMLCanvasElement = element as HTMLCanvasElement;
    const gl = canvas.getContext('webgl');
    if (!gl) { maxTextureSize = 1024; break; }
    const maxTex = gl.getParameter(gl.MAX_TEXTURE_SIZE);
    maxTextureSize = maxTex;
    element.remove();
    break;
  }
  return maxTextureSize;
}
export function GetRenderLevelAndResolution() {
  getMaxTexSize();
  const maxWindowSize = Math.max(window.devicePixelRatio * (window.visualViewport?.width || WindowSizeToLevel[0][0]),
    window.devicePixelRatio * (window.visualViewport?.height || WindowSizeToLevel[0][0]));
  let targetLevel = 1;
  for (const k of WindowSizeToLevel) {
    if (maxWindowSize > k[0]) {
      targetLevel = k[1];
      break;
    }
  }

  let targetResolution = LevelToResolution[targetLevel];
  // this seems unlikly to happen but...
  while (targetResolution > maxTextureSize && LevelToResolution.indexOf(targetResolution) > 0) {
    targetResolution = LevelToResolution[LevelToResolution.indexOf(targetResolution) - 1];
  }


  if (isIos) {
    targetLevel = 0;
    targetResolution = LevelToResolution[0];
  }
  return {
    level: targetLevel, resolution: targetResolution
  };
}

export function GetVideoLevelAndResolution() {
  getMaxTexSize();
  const maxWindowSize = Math.max(window.devicePixelRatio * (window.visualViewport?.width || WindowSizeToLevel[0][0]),
    window.devicePixelRatio * (window.visualViewport?.height || WindowSizeToLevel[0][0]));
  let targetLevel = 1;
  for (const k of WindowSizeToLevel) {
    if (maxWindowSize > k[0]) {
      targetLevel = k[1];
      break;
    }
  }

  let targetResolution = LevelToResolution[targetLevel];
  // this seems unlikly to happen but...
  while (targetResolution > maxTextureSize && LevelToResolution.indexOf(targetResolution) > 0) {
    targetResolution = LevelToResolution[LevelToResolution.indexOf(targetResolution) - 1];
  }
  if (isIos) {
    targetLevel = 0;
    targetResolution = LevelToResolution[0];
  }
  return {
    level: targetLevel, resolution: targetResolution
  };
}

export enum TourChapter {
  Intro = 'Introduction',
  History = 'History',
  Design = 'Design',
  Structure = 'Structure',
  Interior = 'Interior',
  Exterior = 'Exterior',
  WrapUp = 'Wrap Up'
}

export interface TourStopData {
  toPrevVideo?: TourPanoVideo | MediaSource,
  toNextVideo?: TourPanoVideo | MediaSource,
  fromAnyVideo?: MediaSource,
  fromNextVideo?: MediaSource,
  prevUrl: string,
  url: string,
  nextUrl: string,
  image: TourImage[],
  chapter: TourChapter,
  name?: string
}
interface TourData { [Name: string]: TourStopData }
const TOUR_DATA: TourData = {};

// view constraints

//--------------------------------------------
// DESIGN

const design_a_unique_shape_constrain = new TourPanoImageConstraints();
const design_orientation_constrain = new TourPanoImageConstraints(
  { "isHFov": true, "hFov": 90, "vFov": 43, "mobileHOffset": 0, "mobileVOffset": 0, "maxHFov": 20, "minHFov": -20, "maxVFov": 20, "minVFov": -20 }
);
const design_elevation_and_height_constrain = new TourPanoImageConstraints(
  { "isHFov": true, "hFov": 103, "vFov": 70, "mobileHOffset": 0, "mobileVOffset": 5, "maxHFov": 20, "minHFov": -20, "maxVFov": 20, "minVFov": -20 }
);
const design_levels_constrain = new TourPanoImageConstraints(
  { "isHFov": true, "hFov": 99.5, "vFov": 70, "mobileHOffset": 0, "mobileVOffset": 7, "maxHFov": 20, "minHFov": -20, "maxVFov": 20, "minVFov": -20 }
);
const design_color_constrain = new TourPanoImageConstraints(
  { "isHFov": true, "hFov": 90, "vFov": 53, "mobileHOffset": 0, "mobileVOffset": 7.5, "maxHFov": 20, "minHFov": -20, "maxVFov": 20, "minVFov": -20 }
);
const design_cladding_constrain = new TourPanoImageConstraints(
  { "isHFov": true, "hFov": 94, "vFov": 56.5, "mobileHOffset": 0, "mobileVOffset": 0, "maxHFov": 20, "minHFov": -20, "maxVFov": 20, "minVFov": -20 }
);

//--------------------------------------------
// STRUCTURE

const structure_steel_and_concrete_constrain = new TourPanoImageConstraints(
  { "isHFov": true, "hFov": 90, "vFov": 90, "mobileHOffset": 0, "mobileVOffset": 0, "maxHFov": 10, "minHFov": -10, "maxVFov": 10, "minVFov": -10 }
);
const structure_elevator_constrain = new TourPanoImageConstraints(
  { "isHFov": false, "hFov": 90, "vFov": 46.5, "mobileHOffset": 2, "mobileVOffset": 0, "maxHFov": 10, "minHFov": -10, "maxVFov": 10, "minVFov": -10 }
  // { "isHFov": false, "hFov": 90, "vFov": 46.5, "mobileHOffset": 0, "mobileVOffset": 0, "maxHFov": 0, "minHFov": 0, "maxVFov": 0, "minVFov": 0 }
  // { "isHFov": true, "hFov": 90, "vFov": 90, "mobileHOffset": 0, "mobileVOffset": 0, "maxHFov": 10, "minHFov": -10, "maxVFov": 10, "minVFov": -10 }
);
const structure_simulsat_constrain = new TourPanoImageConstraints(
  { "isHFov": false, "hFov": 90, "vFov": 70, "mobileHOffset": -3.5, "mobileVOffset": 0, "maxHFov": 10, "minHFov": -10, "maxVFov": 10, "minVFov": -10 }
);
const structure_repainting_constrain = new TourPanoImageConstraints(
  { "isHFov": true, "hFov": 90, "vFov": 90, "mobileHOffset": 0, "mobileVOffset": 0, "maxHFov": 10, "minHFov": -10, "maxVFov": 10, "minVFov": -10 }
);
const structure_main_building_constrain = new TourPanoImageConstraints(
  { "isHFov": true, "hFov": 90, "vFov": 90, "mobileHOffset": 0, "mobileVOffset": 0, "maxHFov": 10, "minHFov": -10, "maxVFov": 10, "minVFov": -10 }
);
const structure_rooftop_constrain = new TourPanoImageConstraints(
  { "isHFov": true, "hFov": 90, "vFov": 63.5, "mobileHOffset": -5, "mobileVOffset": 6, "maxHFov": 10, "minHFov": -10, "maxVFov": 10, "minVFov": -10 }
);

//--------------------------------------------
// INTERIOR

const interior_transmission_suite_constrain = new TourPanoImageConstraints(
  { "isHFov": false, "hFov": 64.5, "vFov": 75, "mobileHOffset": 0, "mobileVOffset": 0, "maxHFov": 20, "minHFov": -20, "maxVFov": 20, "minVFov": -20 }
);
const interior_broadcast_content_constrain = new TourPanoImageConstraints(
  { "isHFov": false, "hFov": 66.5, "vFov": 54.5, "mobileHOffset": 0, "mobileVOffset": 0, "maxHFov": 5, "minHFov": -5, "maxVFov": 8, "minVFov": -8 }
);
const interior_transmitter_constrain = new TourPanoImageConstraints(
  { "isHFov": false, "hFov": 85.5, "vFov": 54.5, "mobileHOffset": 0, "mobileVOffset": 0, "maxHFov": 5, "minHFov": -5, "maxVFov": 8, "minVFov": -8 }
);
const interior_mask_filter_constrain = new TourPanoImageConstraints(
  { "isHFov": true, "hFov": 105.5, "vFov": 90, "mobileHOffset": 0, "mobileVOffset": 0, "maxHFov": 10, "minHFov": -10, "maxVFov": 10, "minVFov": -10 }
);
const interior_switching_matrix_constrain = new TourPanoImageConstraints(
  { "isHFov": true, "hFov": 82.5, "vFov": 90, "mobileHOffset": 0, "mobileVOffset": 0, "maxHFov": 10, "minHFov": -10, "maxVFov": 10, "minVFov": -10 }
);
const interior_combiner_room_constrain = new TourPanoImageConstraints(
  { "isHFov": true, "hFov": 100, "vFov": 90, "mobileHOffset": 0, "mobileVOffset": 0, "maxHFov": 10, "minHFov": -10, "maxVFov": 5, "minVFov": -5 }
);

//--------------------------------------------
// EXTERIOR

const exterior_transmission_lines_constrain = new TourPanoImageConstraints(
  { "isHFov": false, "hFov": 74, "vFov": 60, "mobileHOffset": 0, "mobileVOffset": 0, "maxHFov": 20, "minHFov": -20, "maxVFov": 20, "minVFov": -20 }
);
const exterior_microwave_antennas_constrain = new TourPanoImageConstraints(
  {"isHFov":false, "hFov":80.5, "vFov":70.5, "mobileHOffset":0.5, "mobileVOffset":0, "maxHFov": 10, "minHFov": -10, "maxVFov": 9, "minVFov": -9}
);

const exterior_fm_antennas_constrain = new TourPanoImageConstraints(
  { "isHFov": true, "hFov": 72, "vFov": 50, "mobileHOffset": 0, "mobileVOffset": 6.5, "maxHFov": 10, "minHFov": -10, "maxVFov": 9, "minVFov": -9 }
); // default

const exterior_main_tv_antennas_constrain = new TourPanoImageConstraints({
  isHFov: false,
  hFov: 120,
  vFov: 50,
  minHFov: -20,
  maxHFov: 20,
  minVFov: -10,
  maxVFov: 10,
}); // default

const wrap_up_constrain = new TourPanoImageConstraints({
  minHFov: 0,
  maxHFov: 0,
  minVFov: 0,
  maxVFov: 0,
}); // default

// tour data

const intro_welcome: TourStopData = {
  chapter: TourChapter.Intro,
  url: '/tour/intro/welcome',
  image: [],
  nextUrl: '/tour/intro/exploring',
  prevUrl: '/'
};
TOUR_DATA[intro_welcome.url] = intro_welcome;

const history_intro: TourStopData = {
  chapter: TourChapter.History,
  url: '/tour/history/intro',
  image: [],
  nextUrl: '/tour/history/site',
  prevUrl: intro_welcome.url //TODO UPDATE
};
TOUR_DATA[history_intro.url] = history_intro;

const history_site: TourStopData = {
  chapter: TourChapter.History,
  url: '/tour/history/site',
  image: [],
  nextUrl: '/tour/history/early-history',
  prevUrl: history_intro.url
};
TOUR_DATA[history_site.url] = history_site;

const history_early_history: TourStopData = {
  chapter: TourChapter.History,
  url: '/tour/history/early-history',
  image: [],
  nextUrl: '/tour/history/a-new-tower',
  prevUrl: history_site.url
};
TOUR_DATA[history_early_history.url] = history_early_history;

const history_a_new_tower: TourStopData = {
  chapter: TourChapter.History,
  url: '/tour/history/a-new-tower',
  toNextVideo: { src: '/videos/tour/history/a-new-tower-to-broadcast-reach/master.m3u8' },
  fromAnyVideo: { src: '/videos/tour/history/a-new-tower/master.m3u8' },
  fromNextVideo: { src: '/videos/tour/history/broadcast-reach-to-a-new-tower/master.m3u8' },
  image: [new TourImage({ src: '/imgs/tour/history/a-new-tower.jpg' })],
  nextUrl: '/tour/history/broadcast-reach',
  prevUrl: history_early_history.url
};
TOUR_DATA[history_a_new_tower.url] = history_a_new_tower;

const history_broadcast_reach: TourStopData = {
  chapter: TourChapter.History,
  url: '/tour/history/broadcast-reach',
  toPrevVideo: { src: '/videos/tour/history/broadcast-reach-to-a-new-tower/master.m3u8' },
  fromAnyVideo: { src: '/videos/tour/history/a-new-tower-to-broadcast-reach/master.m3u8' },
  image: [new TourImage({ src: '/imgs/tour/history/broadcast-reach.jpg' })],
  nextUrl: '/tour/history/construction',
  prevUrl: history_a_new_tower.url
};
TOUR_DATA[history_broadcast_reach.url] = history_broadcast_reach;

const history_construction: TourStopData = {
  chapter: TourChapter.History,
  url: '/tour/history/construction',
  image: [],
  nextUrl: '/tour/design/intro',
  prevUrl: history_a_new_tower.url
};
TOUR_DATA[history_construction.url] = history_construction;

const design_intro: TourStopData = {
  chapter: TourChapter.Design,
  url: '/tour/design/intro',
  image: [],
  nextUrl: '/tour/design/a-unique-shape',
  prevUrl: history_construction.url
};
TOUR_DATA[design_intro.url] = design_intro;

const design_a_unique_shape: TourStopData = {
  chapter: TourChapter.Design,
  url: '/tour/design/a-unique-shape',
  image: [new TourImage({
    src: '/imgs/tour/design/a-unique-shape.jpg',
    constraints: design_a_unique_shape_constrain
  })],
  nextUrl: '/tour/design/orientation',
  prevUrl: design_intro.url,
  toNextVideo: new TourPanoVideo({
    src: '/videos/tour/design/a-unique-shape-to-orientation/master.m3u8',
    start: design_a_unique_shape_constrain,
    end: design_orientation_constrain
  })
};
TOUR_DATA[design_a_unique_shape.url] = design_a_unique_shape;

const design_orientation: TourStopData = {
  chapter: TourChapter.Design,
  url: '/tour/design/orientation',
  nextUrl: '/tour/design/elevation-and-height',
  prevUrl: design_a_unique_shape.url,
  image: [new TourImage({
    src: '/imgs/tour/design/orientation.jpg',
    constraints: design_orientation_constrain
  })],
  toPrevVideo: new TourPanoVideo({
    src: '/videos/tour/design/orientation-to-a-unique-shape/master.m3u8',
    start: design_orientation_constrain,
    end: design_a_unique_shape_constrain
  }),
  toNextVideo: new TourPanoVideo({
    src: '/videos/tour/design/orientation-to-elevation-and-height/master.m3u8',
    srcHFov: 130,
    srcVFov: 130,
    start: design_orientation_constrain,
    end: design_elevation_and_height_constrain
  }),
};
TOUR_DATA[design_orientation.url] = design_orientation;

const design_elevation_and_height: TourStopData = {
  chapter: TourChapter.Design,
  url: '/tour/design/elevation-and-height',
  nextUrl: '/tour/design/levels',
  prevUrl: design_orientation.url,
  image: [new TourImage({
    src: '/imgs/tour/design/elevation-and-height.jpg',
    constraints: design_elevation_and_height_constrain
  })],
  toPrevVideo: new TourPanoVideo({
    src: '/videos/tour/design/elevation-and-height-to-orientation/master.m3u8',
    srcHFov: 130,
    srcVFov: 130,
    start: design_elevation_and_height_constrain,
    end: design_orientation_constrain
  }),
  toNextVideo: new TourPanoVideo({
    src: '/videos/tour/design/elevation-and-height-to-levels/master.m3u8',
    start: design_elevation_and_height_constrain,
    end: design_levels_constrain
  }),
};
TOUR_DATA[design_elevation_and_height.url] = design_elevation_and_height;

const design_levels: TourStopData = {
  chapter: TourChapter.Design,
  url: '/tour/design/levels',
  nextUrl: '/tour/design/color',
  prevUrl: design_elevation_and_height.url,
  image: [new TourImage({
    src: '/imgs/tour/design/levels.jpg',
    constraints: design_levels_constrain
  })],
  toPrevVideo: new TourPanoVideo({
    src: '/videos/tour/design/levels-to-elevation-and-height/master.m3u8',
    start: design_levels_constrain,
    end: design_elevation_and_height_constrain
  }),
  toNextVideo: new TourPanoVideo({
    src: '/videos/tour/design/levels-to-color/master.m3u8',
    start: design_levels_constrain,
    end: design_color_constrain
  }),
};
TOUR_DATA[design_levels.url] = design_levels;

const design_color: TourStopData = {
  chapter: TourChapter.Design,
  url: '/tour/design/color',
  image: [new TourImage({
    src: '/imgs/tour/design/color.jpg',
    constraints: design_color_constrain
  })],
  nextUrl: '/tour/design/cladding',
  prevUrl: design_levels.url,
  toPrevVideo: new TourPanoVideo({
    src: '/videos/tour/design/color-to-levels/master.m3u8',
    start: design_color_constrain,
    end: design_levels_constrain
  }),
  toNextVideo: new TourPanoVideo({
    src: '/videos/tour/design/color-to-cladding/master.m3u8',
    start: design_color_constrain,
    end: design_cladding_constrain
  })
};
TOUR_DATA[design_color.url] = design_color;

const design_cladding: TourStopData = {
  chapter: TourChapter.Design,
  url: '/tour/design/cladding',
  nextUrl: '/tour/structure/intro',
  prevUrl: design_color.url,
  image: [new TourImage({
    src: '/imgs/tour/design/cladding.jpg',
    constraints: design_cladding_constrain,

  })],
  toPrevVideo: new TourPanoVideo({
    src: '/videos/tour/design/cladding-to-color/master.m3u8',
    start: design_cladding_constrain,
    end: design_color_constrain
  }),
};
TOUR_DATA[design_cladding.url] = design_cladding;

const structure_intro: TourStopData = {
  chapter: TourChapter.Structure,
  url: '/tour/structure/intro',
  nextUrl: '/tour/structure/steel-and-concrete',
  prevUrl: design_cladding.url,
  image: [],
};
TOUR_DATA[structure_intro.url] = structure_intro;

const structure_concrete_and_steel: TourStopData = {
  chapter: TourChapter.Structure,
  url: '/tour/structure/steel-and-concrete',
  nextUrl: '/tour/structure/elevator',
  prevUrl: structure_intro.url,
  image: [new TourImage({
    src: '/imgs/tour/structure/steel-and-concrete.jpg',
    constraints: structure_steel_and_concrete_constrain
  })],
  toNextVideo: new TourPanoVideo({
    start: structure_steel_and_concrete_constrain,
    end: structure_elevator_constrain,
    src: '/videos/tour/structure/steel-and-concrete-to-elevator/master.m3u8'
    // src: '/videos/tour/structure/steel-and-concrete-to-elevator.mp4'
  })
};
TOUR_DATA[structure_concrete_and_steel.url] = structure_concrete_and_steel;

const structure_elevator: TourStopData = {
  chapter: TourChapter.Structure,
  url: '/tour/structure/elevator',
  nextUrl: '/tour/structure/simulsat',
  prevUrl: structure_concrete_and_steel.url,
  image: [new TourImage({
    src: '/imgs/tour/structure/elevator.jpg',
    constraints: structure_elevator_constrain
  })],
  toPrevVideo: new TourPanoVideo({
    start: structure_elevator_constrain,
    end: structure_steel_and_concrete_constrain,
    src: '/videos/tour/structure/elevator-to-steel-and-concrete/master.m3u8'
  }),
  toNextVideo: new TourPanoVideo({
    start: structure_elevator_constrain,
    end: structure_simulsat_constrain,
    src: '/videos/tour/structure/elevator-to-simulsat/master.m3u8'
  })
};
TOUR_DATA[structure_elevator.url] = structure_elevator;

const structure_simulsat: TourStopData = {
  chapter: TourChapter.Structure,
  url: '/tour/structure/simulsat',
  nextUrl: '/tour/structure/repainting',
  prevUrl: structure_elevator.url,
  image: [new TourImage({
    src: '/imgs/tour/structure/simulsat.jpg',
    constraints: structure_simulsat_constrain
  })],
  toPrevVideo: new TourPanoVideo({
    start: structure_simulsat_constrain,
    end: structure_elevator_constrain,
    src: '/videos/tour/structure/simulsat-to-elevator/master.m3u8'
  }),
  toNextVideo: new TourPanoVideo({
    start: structure_simulsat_constrain,
    end: structure_repainting_constrain,
    src: '/videos/tour/structure/simulsat-to-repainting/master.m3u8'
  })
};
TOUR_DATA[structure_simulsat.url] = structure_simulsat;

const structure_repainting: TourStopData = {
  chapter: TourChapter.Structure,
  url: '/tour/structure/repainting',
  nextUrl: '/tour/structure/main-building',
  prevUrl: structure_simulsat.url,
  image: [new TourImage({
    src: '/imgs/tour/structure/repainting.jpg',
    constraints: structure_repainting_constrain
  })],
  toPrevVideo: new TourPanoVideo({
    start: structure_repainting_constrain,
    end: structure_simulsat_constrain,
    src: '/videos/tour/structure/repainting-to-simulsat/master.m3u8'
  }),
  toNextVideo: new TourPanoVideo({
    start: structure_repainting_constrain,
    end: structure_main_building_constrain,
    src: '/videos/tour/structure/repainting-to-main-building/master.m3u8'
  })
};
TOUR_DATA[structure_repainting.url] = structure_repainting;

const structure_main_building: TourStopData = {
  chapter: TourChapter.Structure,
  url: '/tour/structure/main-building',
  nextUrl: '/tour/structure/rooftop',
  prevUrl: structure_repainting.url,
  image: [new TourImage({
    src: '/imgs/tour/structure/main-building.jpg',
    constraints: structure_main_building_constrain
  })],
  toPrevVideo: new TourPanoVideo({
    start: structure_main_building_constrain,
    end: structure_repainting_constrain,
    src: '/videos/tour/structure/main-building-to-repainting/master.m3u8'
  }),
  toNextVideo: new TourPanoVideo({
    start: structure_main_building_constrain,
    end: structure_rooftop_constrain,
    src: '/videos/tour/structure/main-building-to-rooftop/master.m3u8'
  })
};
TOUR_DATA[structure_main_building.url] = structure_main_building;

const structure_rooftop: TourStopData = {
  chapter: TourChapter.Structure,
  url: '/tour/structure/rooftop',
  nextUrl: '/tour/interior/intro',
  prevUrl: structure_main_building.url,
  image: [new TourImage({
    src: '/imgs/tour/structure/rooftop.jpg',
    constraints: structure_rooftop_constrain
  })],
  toPrevVideo: new TourPanoVideo({
    start: structure_rooftop_constrain,
    end: structure_repainting_constrain,
    src: '/videos/tour/structure/rooftop-to-main-building/master.m3u8'
  })
};
TOUR_DATA[structure_rooftop.url] = structure_rooftop;


const interior_intro: TourStopData = {
  chapter: TourChapter.Interior,
  name: 'Introduction',
  url: '/tour/interior/intro',
  nextUrl: '/tour/interior/how-it-works',
  prevUrl: structure_rooftop.url,
  image: [],
};
TOUR_DATA[interior_intro.url] = interior_intro;

const interior_how_it_works: TourStopData = {
  chapter: TourChapter.Interior,
  name: 'How It Works',
  url: '/tour/interior/how-it-works',
  nextUrl: '/tour/interior/transmission-suite',
  prevUrl: interior_intro.url,
  image: [],
};
TOUR_DATA[interior_how_it_works.url] = interior_how_it_works;

const interior_transmission_suite: TourStopData = {
  chapter: TourChapter.Interior,
  name: 'Transmission Suite',
  url: '/tour/interior/transmission-suite',
  nextUrl: '/tour/interior/broadcast-content',
  prevUrl: interior_intro.url,
  image: [new TourImage({
    src: '/imgs/tour/interior/transmission-suite.jpg',
    constraints: interior_transmission_suite_constrain
  })],
  toNextVideo: new TourPanoVideo({
    start: interior_transmission_suite_constrain,
    end: interior_broadcast_content_constrain,
    src: '/videos/tour/interior/transmission-suite-to-broadcast-content/master.m3u8'
  })
};
TOUR_DATA[interior_transmission_suite.url] = interior_transmission_suite;

const interior_broadcast_content: TourStopData = {
  chapter: TourChapter.Interior,
  url: '/tour/interior/broadcast-content',
  nextUrl: '/tour/interior/transmitter',
  prevUrl: interior_transmission_suite.url,
  image: [new TourImage({
    src: '/imgs/tour/interior/broadcast-content.jpg',
    constraints: interior_broadcast_content_constrain
  })],
  toPrevVideo: new TourPanoVideo({
    start: interior_broadcast_content_constrain,
    end: interior_transmission_suite_constrain,
    src: '/videos/tour/interior/broadcast-content-to-transmission-suite/master.m3u8'
  }),
  toNextVideo: new TourPanoVideo({
    start: interior_broadcast_content_constrain,
    end: interior_transmitter_constrain,
    src: '/videos/tour/interior/broadcast-content-to-transmitter/master.m3u8'
  })
};
TOUR_DATA[interior_broadcast_content.url] = interior_broadcast_content;

const interior_transmitter: TourStopData = {
  chapter: TourChapter.Interior,
  url: '/tour/interior/transmitter',
  nextUrl: '/tour/interior/mask-filter',
  prevUrl: interior_broadcast_content.url,
  image: [new TourImage({
    src: '/imgs/tour/interior/transmitter.jpg',
    constraints: interior_transmitter_constrain
  })],
  toPrevVideo: new TourPanoVideo({
    start: interior_transmitter_constrain,
    end: interior_broadcast_content_constrain,
    src: '/videos/tour/interior/transmitter-to-broadcast-content/master.m3u8'
  }),
  toNextVideo: new TourPanoVideo({
    start: interior_transmitter_constrain,
    end: interior_mask_filter_constrain,
    src: '/videos/tour/interior/transmitter-to-mask-filter/master.m3u8'
  })
};
TOUR_DATA[interior_transmitter.url] = interior_transmitter;

const interior_mask_filter: TourStopData = {
  chapter: TourChapter.Interior,
  url: '/tour/interior/mask-filter',
  nextUrl: '/tour/interior/switching-matrix',
  prevUrl: interior_transmitter.url,
  image: [new TourImage({
    src: '/imgs/tour/interior/mask-filter.jpg',
    constraints: interior_mask_filter_constrain
  })],
  toPrevVideo: new TourPanoVideo({
    start: interior_mask_filter_constrain,
    end: interior_transmitter_constrain,
    src: '/videos/tour/interior/mask-filter-to-transmitter/master.m3u8'
  }),
  toNextVideo: new TourPanoVideo({
    start: interior_mask_filter_constrain,
    end: interior_switching_matrix_constrain,
    src: '/videos/tour/interior/mask-filter-to-switching-matrix/master.m3u8'
  })
};
TOUR_DATA[interior_mask_filter.url] = interior_mask_filter;

const interior_switching_matrix: TourStopData = {
  chapter: TourChapter.Interior,
  url: '/tour/interior/switching-matrix',
  nextUrl: '/tour/interior/combiner-room',
  prevUrl: interior_mask_filter.url,
  image: [new TourImage({
    src: '/imgs/tour/interior/switching-matrix.jpg',
    constraints: interior_switching_matrix_constrain
  })],
  toPrevVideo: new TourPanoVideo({
    start: interior_switching_matrix_constrain,
    end: interior_mask_filter_constrain,
    src: '/videos/tour/interior/switching-matrix-to-mask-filter/master.m3u8'
  }),
  toNextVideo: new TourPanoVideo({
    start: interior_switching_matrix_constrain,
    end: interior_combiner_room_constrain,
    src: '/videos/tour/interior/switching-matrix-to-combiner-room/master.m3u8'
  })
};
TOUR_DATA[interior_switching_matrix.url] = interior_switching_matrix;

const interior_combiner_room: TourStopData = {
  chapter: TourChapter.Interior,
  url: '/tour/interior/combiner-room',
  nextUrl: '/tour/exterior/intro',
  prevUrl: interior_switching_matrix.url,
  image: [new TourImage({
    src: '/imgs/tour/interior/combiner-room.webp',
    constraints: interior_combiner_room_constrain
  })],
  toPrevVideo: new TourPanoVideo({
    start: interior_combiner_room_constrain,
    end: interior_switching_matrix_constrain,
    src: '/videos/tour/interior/combiner-room-to-switching-matrix/master.m3u8'
  })
};
TOUR_DATA[interior_combiner_room.url] = interior_combiner_room;



const exterior_intro: TourStopData = {
  chapter: TourChapter.Exterior,
  name: 'Intro',
  image: [],
  url: '/tour/exterior/intro',
  prevUrl: '/',
  nextUrl: '/tour/exterior/transmission-lines',
};
TOUR_DATA[exterior_intro.url] = exterior_intro;

const exterior_transmission_lines: TourStopData = {
  chapter: TourChapter.Exterior,
  name: 'Transmission Lines',
  url: '/tour/exterior/transmission-lines',
  prevUrl: exterior_intro.url,
  nextUrl: '/tour/exterior/microwave-antennas',
  image: [new TourImage({
    src: '/imgs/tour/exterior/transmission-lines.jpg',
    constraints: exterior_transmission_lines_constrain
  })],
  toNextVideo: new TourPanoVideo({
    start: exterior_transmission_lines_constrain,
    end: exterior_microwave_antennas_constrain,
    src: '/videos/tour/exterior/transmission-lines-to-microwave-antennas/master.m3u8'
  })
};
TOUR_DATA[exterior_transmission_lines.url] = exterior_transmission_lines;


const exterior_microwave_antennas: TourStopData = {
  chapter: TourChapter.Exterior,
  name: 'Microwave Antennas',
  url: '/tour/exterior/microwave-antennas',
  prevUrl: exterior_transmission_lines.url,
  nextUrl: '/tour/exterior/fm-antennas',
  image: [new TourImage({
    src: '/imgs/tour/exterior/microwave-antennas.jpg',
    constraints: exterior_microwave_antennas_constrain
  })],
  toPrevVideo: new TourPanoVideo({
    start: exterior_microwave_antennas_constrain,
    end: exterior_transmission_lines_constrain,
    src: '/videos/tour/exterior/microwave-antennas-to-transmission-lines/master.m3u8'
  }),
  toNextVideo: new TourPanoVideo({
    start: exterior_microwave_antennas_constrain,
    end: exterior_fm_antennas_constrain,
    src: '/videos/tour/exterior/microwave-antennas-to-fm-antennas/master.m3u8'
  })
};
TOUR_DATA[exterior_microwave_antennas.url] = exterior_microwave_antennas;

const exterior_fm_antennas: TourStopData = {
  chapter: TourChapter.Exterior,
  name: 'FM Antennas',
  url: '/tour/exterior/fm-antennas',
  prevUrl: exterior_microwave_antennas.url,
  nextUrl: '/tour/exterior/main-tv-antennas',
  image: [new TourImage({
    src: '/imgs/tour/exterior/fm-antennas.jpg',
    constraints: exterior_fm_antennas_constrain
  })],
  toPrevVideo: new TourPanoVideo({
    start: exterior_fm_antennas_constrain,
    end: exterior_microwave_antennas_constrain,
    src: '/videos/tour/exterior/fm-antennas-to-microwave-antennas/master.m3u8'
  }),
  toNextVideo: new TourPanoVideo({
    start: exterior_fm_antennas_constrain,
    end: exterior_main_tv_antennas_constrain,
    src: '/videos/tour/exterior/fm-antennas-to-main-tv-antennas/master.m3u8'
  })
};
TOUR_DATA[exterior_fm_antennas.url] = exterior_fm_antennas;

const exterior_main_tv_antennas: TourStopData = {
  chapter: TourChapter.Exterior,
  name: 'Main TV Antennas',
  url: '/tour/exterior/main-tv-antennas',
  prevUrl: exterior_fm_antennas.url,
  nextUrl: '/tour/wrap-up',
  image: [new TourImage({
    src: '/imgs/tour/exterior/main-tv-antennas.jpg',
    constraints: exterior_main_tv_antennas_constrain
  })],
  toPrevVideo: new TourPanoVideo({
    start: exterior_main_tv_antennas_constrain,
    end: exterior_fm_antennas_constrain,
    src: '/videos/tour/exterior/main-tv-antennas-to-fm-antennas/master.m3u8'
  }),
  toNextVideo: new TourPanoVideo({
    start: exterior_main_tv_antennas_constrain,
    end: wrap_up_constrain,
    src: '/videos/tour/exterior/main-tv-antennas-to-wrap-up/master.m3u8'
  }),
};
TOUR_DATA[exterior_main_tv_antennas.url] = exterior_main_tv_antennas;

const wrap_up: TourStopData = {
  chapter: TourChapter.WrapUp,
  name: 'Wrap Up',
  url: '/tour/wrap-up',
  prevUrl: exterior_fm_antennas.url,
  nextUrl: '/tour/exterior/ending',
  image: [new TourImage({
    src: '/imgs/tour/exterior/wrap-up.webp',
  })]
};
TOUR_DATA[wrap_up.url] = wrap_up;

// pull name from site structure data
const pathToTitle = sitePages.reduce((m, v) => {
  if (v.title) {
    m[v.path] = v.title;
  }
  return m;
}, {} as { [id: string]: string });
Object.values(TOUR_DATA).forEach(stop => {
  if (!stop.name && pathToTitle[stop.url]) {
    stop.name = pathToTitle[stop.url];
  }
});

export const TOUR_CHAPTERS = Object.values(TOUR_DATA).reduce((m, v) => {
  if (!(v.chapter in m)) {
    m[v.chapter] = [];
  }
  m[v.chapter].push(v);
  m[v.chapter].sort((a: TourStopData, b: TourStopData) => {
    if (a.nextUrl === b.url) {
      return -1;
    } else if (b.nextUrl === a.url) {
      return 1;
    }
    return 0;
  });
  return m;
}, {} as { [Name in TourChapter]: TourStopData[] });

export default TOUR_DATA;

// const fovs: { [Name: string]: string[] } = {};
// for (const stop of Object.values(TOUR_DATA)) {
//   const img = stop.image[0];
//   if (!img) { continue; }
//   const h = img.hFov + Math.abs(img.maxHFov) + Math.abs(img.minHFov);
//   const v = img.vFov + Math.abs(img.maxVFov) + Math.abs(img.minVFov);
//   fovs[`${h}:${v}`] = fovs[`${h}:${v}`] || [];
//   fovs[`${h}:${v}`].push(stop.url);
// }
// console.log(fovs);

export const TOUR_CHAPTERS_TO_URLS = {
  [TourChapter.Design]: '/tour/design',
  [TourChapter.WrapUp]: '/tour/warp-up',
  [TourChapter.Exterior]: '/tour/exterior',
  [TourChapter.History]: '/tour/history',
  [TourChapter.Interior]: '/tour/interior',
  [TourChapter.Intro]: '/tour/intro',
  [TourChapter.Structure]: '/tour/structure',
};

export const TOUR_CHAPTERS_ORDER = [
  TourChapter.Intro,
  TourChapter.History,
  TourChapter.Design,
  TourChapter.Structure,
  TourChapter.Interior,
  TourChapter.Exterior,
  TourChapter.WrapUp
];