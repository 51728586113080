import { useState } from "react";
import './About.scss';
import useBasicTourTrans from "../util/useBasicTourTrans";
const loc = '/about';
function About() {
  const [show, setShow] = useState(false);
  const { onPageTransInDone, onPageTransOutDone } = useBasicTourTrans({
    setShow,
    name: loc,
    isLocation: loc,
    autoCompleteLoad: true
  });

  const onTransition = (e: React.TransitionEvent<HTMLDivElement>) => {
    if (e.currentTarget) {
      const opacity = window.getComputedStyle(e.currentTarget).opacity;
      if (e.propertyName === 'opacity' && opacity === '1') {
        onPageTransInDone();
      } else if (e.propertyName === 'opacity' && opacity === '0') {
        onPageTransOutDone();
      }
    }
  };
  return <div className={["about", show ? "show" : ""].join(' ')} onTransitionEnd={onTransition}>
    <div className="top-edge"></div>
    <div className="main-text">
      <h2>About this Website </h2>
      <p>Mauris vel sollicitudin arcu. In tempor quis ex eget dictum. Pellentesque accumsan laoreet urna in pretium. Proin tincidunt posuere nisi non faucibus. </p>
    </div>
    <div className="details">
      <div className="section">
        <h3>Section Name</h3>
        <p>
          <strong>Name</strong> - Contribution<br />
          <strong>Name</strong> - Contribution<br />
          <strong>Name</strong> - Contribution<br />
          <strong>Name</strong> - Contribution<br />
          <strong>Name</strong> - Contribution<br />
          <strong>Name</strong> - Contribution<br />
        </p>
      </div>
      <div className="section">
        <h3>Section Name</h3>
        <p>
          <strong>Name</strong> - Contribution<br />
          <strong>Name</strong> - Contribution<br />
          <strong>Name</strong> - Contribution<br />
          <strong>Name</strong> - Contribution<br />
          <strong>Name</strong> - Contribution<br />
        </p>
      </div>

      <div className="section">
        <h3>Section Name</h3>
        <p>
          <strong>Name</strong> - Contribution<br />
          <strong>Name</strong> - Contribution<br />
          <strong>Name</strong> - Contribution<br />
          <strong>Name</strong> - Contribution<br />
          <strong>Name</strong> - Contribution<br />
          <strong>Name</strong> - Contribution<br />
          <strong>Name</strong> - Contribution<br />
          <strong>Name</strong> - Contribution<br />
          <strong>Name</strong> - Contribution<br />
        </p>
      </div>
      <div className="section">
        <h3>Section Name</h3>
        <p>
          <strong>Name</strong> - Contribution<br />
          <strong>Name</strong> - Contribution<br />
          <strong>Name</strong> - Contribution<br />
          <strong>Name</strong> - Contribution<br />
          <strong>Name</strong> - Contribution<br />
          <strong>Name</strong> - Contribution<br />
        </p>
      </div>
    </div>
  </div>;
}
export default About;